<template>
  <a-modal
    :title="$t('资产卡片')"
    v-model="visible"
    width="1000px"
    :keyboard="false"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <CommonTable
      ref="ModalCommonTableRef"
      :columns.sync="columns"
      :showSelect="true"
      :selectedRowKeys.sync="selectedRowKeys"
      :selectedRows.sync="selectedRows"
      :dataSource="tableData"
      :loading="loading"
      :total="total"
      :scroll="{ x: 1500 }"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:doc_sn="{ text }">
        <span class="text-link">{{ text }}</span>
      </template>
    </CommonTable>
  </a-modal>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import { convertKeysToCamelCase } from '@/common'
export default {
  props: {
    ownerBusinessUnitCode: {
      type: String,
    },
    assetOperateCode: {
      type: String, //资产变更 CHANGE 资产清理 CLEANUP 资产减值 IMPAIRMENT
    },
    hasSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableData: [],
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],

      columns: [
        {
          title: this.$t('卡片编号'),
          dataIndex: 'cardNo',
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        {
          title: this.$t('货主组织'),
          dataIndex: 'ownerBusinessUnitName',
        },
        {
          title: this.$t('资产数量'),
          dataIndex: 'quantity',
        },
        {
          title: this.$t('开始使用日期'),
          dataIndex: 'startUseDate',
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
        },
      ],
    }
  },
  methods: {
    open() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = true
      this.$nextTick(() => {
        this.$refs.ModalCommonTableRef.queryList()
      })
    },
    close() {
      this.visible = false
    },
    async getList() {
      this.loading = true
      await http({
        url: api.canChangeAssetCardList,
        data: {
          ownerBusinessUnitCode: this.ownerBusinessUnitCode,
          assetOperateCode: this.assetOperateCode,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list.filter((item) => !this.hasSelected?.includes(item.id))
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    onSelectChange(ids, rows) {
      this.selectedRows = rows
    },
    handleOk() {
      this.$emit('ok', this.selectedRows)
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>
